import type { SearchLocation } from '~/modules/search';
import type { DropdownItem } from '../component/enquiry-form/EnquiryForm';
import type { BasicPlace } from '~/modules/location/type';

// deprecated, please use the dropdownOptions in the usePlaceFinder instead
// hook: packages/web/app/modules/location/hook/use-place-finder.ts
export const formatSuburbDropdownOptions = (
    places: SearchLocation[],
    selectedIndex = -1
): DropdownItem<BasicPlace>[] => {
    return (
        places
            .map((p, index) => {
                const header = getLocationName(p);
                return {
                    header,
                    value: p,
                    selected: index === selectedIndex,
                };
            })
            .filter((suggestion) => suggestion && suggestion.header !== '') ??
        []
    );
};
type DropdownItemWithPrefix<T> = DropdownItem<T> & { prefix: string };

// declare a new interface to avoid to influence filter-url-helper
interface LocationRecommendation extends SearchLocation {
    fullAddress?: string;
}

const getLocationName = (location: LocationRecommendation): string => {
    if (location.fullAddress) return location.fullAddress;
    const { suburb, district, city, region, state } = location;
    const segments = [
        suburb?.trim(),
        district?.trim(),
        city?.trim(),
        region?.trim(),
        state?.trim(),
    ].filter((segment) => segment && segment !== '');

    const uniqSegments = [...new Set(segments)].splice(0, 3);
    return uniqSegments.join(', ');
};

export const formatSuburbDropdownOptionsWithPrefix = (
    places: SearchLocation[],
    selectedIndex = -1
): Array<DropdownItemWithPrefix<BasicPlace>> => {
    const uniquePlaces: Record<string, DropdownItemWithPrefix<BasicPlace>> = {};

    places.forEach((p, index) => {
        const header = getLocationName(p);

        //remove duplicate when dropdown has already selected option
        if (header !== '') {
            const key = `${header}_${getPrefix(p)}`;
            if (!uniquePlaces[key] || index === selectedIndex) {
                uniquePlaces[key] = {
                    header,
                    value: p,
                    selected: index === selectedIndex,
                    prefix: getPrefix(p),
                };
            }
        }
    });

    return Object.values(uniquePlaces);
};

const getPrefix = (place: SearchLocation) => {
    let prefix = 'Other,';
    if (place.suburb) {
        prefix = 'Suburb,';
    } else if (place.district) {
        prefix = 'District,';
    } else if (place.city) {
        prefix = 'City,';
    } else if (place.region) {
        prefix = `Region,`;
    } else if (place.state) {
        prefix = 'State,';
    } else if (place.country) {
        prefix = 'Country,';
    }
    return prefix;
};
